import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import SeedListing from "../components/SeedListing";
import Seo from "../components/Seo";
import config from "../../data/SiteConfig";

const Seeds = ({ data }) => (
  <Layout>
    <main>
      <Helmet title={config.siteTitle} />
      <Seo />
      <SeedListing seedEdges={data.allSeed.edges} />
    </main>
  </Layout>
);

export default Seeds;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query SeedsQuery {
    allSeed(limit: 2000) {
      edges {
        node {
          primary_id
          images {
            image_label
            image_sharp {
              gatsbyImageData(
                width: 600
                placeholder: BLURRED
                transformOptions: { fit: COVER }
              )
            }
          }
          variety {
            name
            description
            genus
            species
            general_common_name
          }
        }
      }
    }
  }
`;
