import React from "react";
import { withPrefix } from "gatsby";
import Masonry from "react-masonry-component";
import useCartItems from "../utils/oisb-cart-items";
import IconButton from "@mui/material/IconButton";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { styled } from "@mui/material/styles";

const SeedCard = styled(Card)(
  ({ theme }) => `
      width: 325px;
      margin-left: 15px;
      margin-top: 15px;
`
);

const AddToCartButtonPlaceholder = styled("div")(`
  display: inline-block;
  width: 51px;
  height: 43px;
`);

const SeedListing = ({ seedEdges }) => {
  // Copied from https://stackoverflow.com/a/40382963
  function shorten(str, maxLen, separator = " ") {
    if (str.length <= maxLen) return str;
    return str.substr(0, str.lastIndexOf(separator, maxLen)) + "...";
  }

  const [cartItems, setCartItems] = useCartItems();

  return (
    <Masonry>
      {seedEdges.map((seed) => (
        <SeedCard key={seed.node.primary_id}>
          <CardActionArea href={withPrefix(`/seeds/${seed.node.primary_id}`)}>
            {seed.node.images[0]?.image_sharp && (
              <GatsbyImage
                image={getImage(seed.node.images[0]?.image_sharp)}
                alt={seed.node.variety.name}
              />
            )}
            <CardContent>
              {(seed.node.variety.general_common_name ||
                (seed.node.variety.genus && seed.node.variety.species)) && (
                <Typography
                  sx={{ fontSize: 14 }}
                  color="textSecondary"
                  gutterBottom
                >
                  {seed.node.variety.general_common_name && (
                    <span style={{ textTransform: "capitalize" }}>
                      <b>
                        {seed.node.variety.general_common_name.toLowerCase()}:{" "}
                      </b>
                    </span>
                  )}
                  {seed.node.variety.genus && seed.node.variety.species && (
                    <span>
                      {[
                        seed.node.variety.genus,
                        seed.node.variety.species,
                      ].join(", ")}
                    </span>
                  )}
                </Typography>
              )}
              <Typography variant="h5" component="h2">
                {seed.node.variety.name}
              </Typography>
              <Typography sx={{ marginBottom: "12px" }} color="textSecondary">
                {seed.node.primary_id}
              </Typography>
              <Typography variant="body2" component="p">
                {shorten(seed.node.variety.description, 100)}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            {(!cartItems
              .map((ci) => ci.seed_node.primary_id)
              .includes(seed.node.primary_id) && (
              <IconButton
                aria-label="Add to cart"
                onClick={(e) => {
                  e.preventDefault();
                  const cartItem = {
                    seed_node: seed.node,
                  };
                  setCartItems([...cartItems, cartItem]);
                }}
              >
                <AddShoppingCartIcon />
              </IconButton>
            )) || <AddToCartButtonPlaceholder></AddToCartButtonPlaceholder>}
            <Button size="small">Learn More</Button>
          </CardActions>
        </SeedCard>
      ))}
    </Masonry>
  );
};

export default SeedListing;
